<script setup>
import AuthenticatedLayout from "@/Layouts/Authenticated.vue";
import { Head,useForm } from "@inertiajs/inertia-vue3";
import { Table } from "@protonemedia/inertiajs-tables-laravel-query-builder";
import { EyeIcon,PencilAltIcon,TrashIcon,CheckIcon,XIcon,DocumentTextIcon} from "@heroicons/vue/outline";
import Button from '@/Components/Button.vue'

defineProps(["users"]);

const form = useForm({
    status: "",
    file: "",
});

</script>

<template>
    <Head title="Users Management" />

    <AuthenticatedLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Users Management
            </h2>
        </template>

        <div class="flex items-center flex-1 w-full ">
            <div class="w-full h-full min-h-screen px-6 py-4 overflow-hidden bg-white shadow-md  sm:rounded-lg dark:bg-dark-eval-1">

                <div class="max-w-8xl mx-auto sm:px-6 lg:px-8">
                    <Table :resource="users"></Table>
                </div>
            </div>
        </div>
    </AuthenticatedLayout>
</template>
<script>
export default {
    components: {
        // ComponentModal,
    },
};
</script>
